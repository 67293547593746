import React, { useState } from "react";
import { Link } from "react-router-dom";
import Line from "../assets/Line3.svg";
import Edukasi1 from "../assets/edukasi1.svg";
import "./content.css";

function Artikel() {
  const [fillActive, setFillActive] = useState("semua");
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 4;

  const sejarahCards = [
    {
      title: "Sejarah terbentuknya Joglo Bale Agung",
      description:
        "Program yang diinisiasi Dr Ir Zahrul Mufrodi, ST, MT, IPM selaku ketua dengan anggota Bambang Robiin, ST, M.Eng dan Rachma Tia Evitasari, ST, M.Eng bertujuan untuk meningkatkan kapasitas produksi dan branding produk batik tulis pewarna alami pada Balai Agung Cendana Batik Tulis Yogyakarta.",
      description2:
        "BEKERJA sama dengan Joglo Balai Agung Cendana, Semaki, Yogyakarta, Universitas Ahmad Dahlan (UAD) melalui Lembaga Penelitian dan Pengabdian kepada Masyarakat (LPPM) mengadakan pelatihan batik tulis dan teknologi pewarna alami, sekaligus juga pencanangan masalah pemasaran melalui website, Senin (14/6/2021).",
      type: "Sejarah",
      date: "2023-03-29",
      imageUrl: "https://che.uad.ac.id/wp-content/uploads/Batik-Warna-Alami-2.jpeg",
    },
    {
      title: "Joglo Bale Agung Cendana",
      description:
        "Program yang diinisiasi Dr Ir Zahrul Mufrodi, ST, MT, IPM selaku ketua dengan anggota Bambang Robiin, ST, M.Eng dan Rachma Tia Evitasari, ST, M.Eng bertujuan untuk meningkatkan kapasitas produksi dan branding produk batik tulis pewarna alami pada Balai Agung Cendana Batik Tulis Yogyakarta.",
      description2: "Program ini diinisiasi oleh Universitas Ahmad Dahlan dalam rangka pembelajaran berbasis proyek (project based learning) untuk menumbuhkan kecintaan batik sejak dini melalui pelatihan batik dengan pewarna alami.",
      type: "Sejarah",
      date: "2023-03-28",
      imageUrl: "https://img.harianjogja.com/posts/2021/06/14/1074436/uad.jpg",
    },
  ];

  const edukasiCards = [
    {
      title: "UAD Berbagi ",
      description:
        "Rangkaian Gebyar Batik Sleman: Bazaar, Pameran Batik, Pemeran kerajinan, Fashion dari berbagai kompetisi kategori anak dan dewasa, workshop, penampilan dari berbagai penampil ternama, penganugrahan trophy pemenang lomba!",
      description2:
        "Di hari pertama, Gebyar Batik Sleman menyajikan rangkaian kegiatan yakni Lomba Mewarnai Tema Batik Sleman dan Bisnis Talk 'Semua Bisa Jadi Wirausaha!' di Gedung Dekranasda). Sementara Play & Learn, Batik Fun Art, dan Special Performance dilaksanakan di Sleman City Hall.",
      type: "Edukasi",
      date: "2023-03-30",
      imageUrl: Edukasi1,
    },
    {
      title: "UNS Kunjungan Joglo Bale Agung",
      description: "Persembahan Dinas Perindustrian Dan Perdagangan Daerah Istimewa Yogyakarta. Kegiatan Ini didanai dari Dana Keistimewaan Daerah Istimewa Yogyakarta Tahun 2022",
      description2:
        "Di hari pertama, Gebyar Batik Sleman menyajikan rangkaian kegiatan yakni Lomba Mewarnai Tema Batik Sleman dan Bisnis Talk 'Semua Bisa Jadi Wirausaha!' di Gedung Dekranasda). Sementara Play & Learn, Batik Fun Art, dan Special Performance dilaksanakan di Sleman City Hall.",
      type: "Edukasi",
      date: "2023-03-26",
      imageUrl: "https://visitingjogja.jogjaprov.go.id/web/wp-content/uploads/2022/10/festival-batik.jpg",
    },
  ];

  const handleFillClick = (category) => {
    setFillActive(category);
    setCurrentPage(1);
  };

  const paginate = (cards) => {
    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    return cards.slice(startIndex, endIndex);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = (totalCards) => {
    const pageCount = Math.ceil(totalCards / cardsPerPage);
    const buttons = [];

    for (let i = 1; i <= pageCount; i++) {
      buttons.push(
        <button key={i} className={`btn btn-${currentPage === i ? "dark" : "outline-dark"} mx-1`} onClick={() => handlePageClick(i)}>
          {i}
        </button>
      );
    }

    return buttons;
  };

  const allCards = [...sejarahCards, ...edukasiCards];

  const filteredCards = fillActive === "semua" ? allCards : allCards.filter((card) => card.type === fillActive);

  filteredCards.sort((a, b) => new Date(b.date) - new Date(a.date));

  const displayedCards = paginate(filteredCards);

  return (
    <>
      <div className="container mb-4">
        <h1 className="container mt-5 fw-semibold">Artikel Terbaru</h1>
        <img src={Line} alt="React Logo" className="mb-3" />
      </div>

      <div className="container">
        <div className="d-flex justify-content-end mb-3">
          <button className={`btn btn-custom-size btn-${fillActive === "semua" ? "dark" : "outline-dark"} mx-1`} onClick={() => handleFillClick("semua")}>
            Semua
          </button>
          <button className={`btn btn-custom-size btn-${fillActive === "Sejarah" ? "dark" : "outline-dark"} mx-1`} onClick={() => handleFillClick("Sejarah")}>
            Sejarah
          </button>
          <button className={`btn btn-custom-size btn-${fillActive === "Edukasi" ? "dark" : "outline-dark"} mx-1`} onClick={() => handleFillClick("Edukasi")}>
            Edukasi
          </button>
        </div>

        {displayedCards.map((card) => (
          <div key={card.title} className="card mb-3 card-custom">
            <div className="col-md-4 image-column-custom">
              <img src={card.imageUrl} alt={card.title} className="img-fluid card-image-custom" />
            </div>

            <div className="col-md-8">
              <div className="card-body card-body-custom">
                <h5 className="card-title card-title-custom">{card.title}</h5>
                <p className="card-text mb-2">
                  <small className="text-muted date-semi-bold">
                    {new Intl.DateTimeFormat("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    }).format(new Date(card.date))}
                  </small>
                </p>
                <p className="card-text">{card.description}</p>
              </div>
              <div className="card-footer card-footer-custom bg-transparent border-top-0">
                <Link className="btn-modif" to={`/article/${card.title}`}>
                  Details
                </Link>
              </div>
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-center mt-3 mb-5">{renderPaginationButtons(filteredCards.length)}</div>
      </div>
    </>
  );
}

export default Artikel;
