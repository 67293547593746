import { MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import "./custom.css";

const Footer = () => {
  return (
    <MDBFooter bgColor="light" className="text-center text-lg-start text-muted">
      <section className="d-flex justify-content-center justify-content-lg-between p-4"></section>

      <section className="">
        <MDBContainer className="text-center text-md-start">
          <MDBRow className="">
            <MDBCol md="2" lg="4" xl="3" className="mx-auto mb-4 mt-4">
              <img src={require("../assets/logobrown.png")} alt="Logo" width="100" height="100" />
              <h6 className="text-uppercase fw-bold mb-4 mt-5">Joglo Bale Agung Cendana</h6>
              <p>Jl. Cendana No.10 B Semaki, Kec. Umbulharjo, Kota Yogyakarta</p>
              <p>
                <a href="/pelatihan" target={"_blank"} className="text-decoration-none" rel="noreferrer">
                  Kontak
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4"></MDBCol>

            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15811.576925059606!2d110.37588666977537!3d-7.801021400000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a577be2f1aa2b%3A0xa2afcf1b6c58a40b!2sJl.%20Cendana%20No.10%2C%20Semaki%2C%20Kec.%20Umbulharjo%2C%20Kota%20Yogyakarta%2C%20Daerah%20Istimewa%20Yogyakarta%2055166!5e0!3m2!1sid!2sid!4v1679509590892!5m2!1sid!2sid"
                style={{ border: 0 }}
                allowFullScreen={true}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
                className="map-iframe"
              />
            </div>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
        <div className="mb-3">
          <a href="/" className="me-4 text-reset">
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href="/" className="me-4 text-reset">
            <MDBIcon fab icon="twitter" />
          </a>
          <a href="/" className="me-4 text-reset">
            <MDBIcon fab icon="instagram" />
          </a>
        </div>
        © 2023 Copyright | &nbsp;
        <a className="text-reset fw-bold" href="/">
          Joglo Bale Agung Cendana
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;
