import axios from "axios";
import React, { useEffect, useState } from "react";
import Line from "../assets/Line3.svg";
import "./card.css";

function Event() {
  const [fillActive, setFillActive] = useState("semua");
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 4;
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("YOUR_API_ENDPOINT");
        setCards(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFillClick = (category) => {
    setFillActive(category);
    setCurrentPage(1);
  };

  const paginate = (cards) => {
    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    return cards.slice(startIndex, endIndex);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = (totalCards) => {
    const pageCount = Math.ceil(totalCards / cardsPerPage);
    const buttons = [];

    for (let i = 1; i <= pageCount; i++) {
      buttons.push(
        <button key={i} className={`btn btn-${currentPage === i ? "dark" : "outline-dark"} mx-1`} onClick={() => handlePageClick(i)}>
          {i}
        </button>
      );
    }

    return buttons;
  };

  const filteredCards = fillActive === "semua" ? cards : cards.filter((card) => card.type === fillActive);

  filteredCards.sort((a, b) => new Date(b.date) - new Date(a.date));

  const displayedCards = paginate(filteredCards);

  return (
    <>
      <div className="container mb-4">
        <h1 className="container mt-5 fw-semibold">Event Terbaru</h1>
        <img src={Line} alt="React Logo" className="mb-3" />
      </div>

      <div className="container">
        <div className="d-flex justify-content-end mb-3">
          <button className={`btn btn-custom-size btn-${fillActive === "semua" ? "dark" : "outline-dark"} mx-1`} onClick={() => handleFillClick("semua")}>
            Semua
          </button>
          <button className={`btn btn-custom-size btn-${fillActive === "pelatihan" ? "dark" : "outline-dark"} mx-1`} onClick={() => handleFillClick("pelatihan")}>
            Pelatihan
          </button>
          <button className={`btn btn-custom-size btn-${fillActive === "pameran" ? "dark" : "outline-dark"} mx-1`} onClick={() => handleFillClick("pameran")}>
            Pameran
          </button>
        </div>

        {displayedCards.map((card) => (
          <div key={card.title} className="card mb-3 card-custom">
            <div className="row g-0">
              <div className="col-md-4">
                <img src={card.imageUrl} alt={card.title} className="img-fluid" style={{ objectFit: "cover", height: "400px", width: "100%" }} />
              </div>
              <div className="col-md-8">
                <div className="card-body card-body-custom">
                  <h5 className="card-title">{card.title}</h5>
                  <p className="card-text">{card.description}</p>
                  <p className="card-text">
                    <small className="text-muted date-semi-bold">
                      {new Intl.DateTimeFormat("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }).format(new Date(card.date))}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="d-flex justify-content-center mt-3 mb-5">{renderPaginationButtons(filteredCards.length)}</div>
      </div>
    </>
  );
}

export default Event;
