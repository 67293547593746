import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import "./form.css";

const DataPendaftar = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const nama = e.target.elements.nama.value;
    const email = e.target.elements.email.value;
    const noTelp = "+62" + e.target.elements.noTelp.value;

    if (!nama || !email || !noTelp) {
      alert("Lengkapi Semua Form!!");
      return;
    }
  };

  const handleContact = () => {
    const phoneNumber = "+6287700537898";
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  return (
    <Container className={`d-flex justify-content-center dataPendaftar-container`}>
      <Row>
        <Col xs={12} className="dataPendaftar-col">
          <Card className="dataPendaftar-card">
            <Card.Body>
              <Card.Title>Data Pendaftar</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Nama Pendaftar</Form.Label>
                  <Form.Control type="text" placeholder="Masukkan nama pendaftar" name="nama" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Masukkan email" name="email" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>No Telp</Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1" style={{ height: "64px" }}>
                      +62
                    </InputGroup.Text>
                    <Form.Control type="tel" pattern="[0-9]*" inputMode="numeric" placeholder="Masukkan nomor telepon" name="noTelp" className="form-control" style={{ height: "64px" }} />
                  </InputGroup>
                </Form.Group>
                <Button type="submit" className="daftarBtn">
                  Daftar
                </Button>
                <br />
                <a href={`https://wa.me/+6287700537898`} target="_blank" rel="noreferrer">
                  <Button className="hubungiBtn" onClick={handleContact}>
                    <FontAwesomeIcon icon={faWhatsapp} /> Hubungi Langsung
                  </Button>
                </a>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DataPendaftar;
