import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import "./form.css";

const FormHome = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      const response = await axios.post("http://localhost:8000/api/store-message", formData);
      console.log(response.data);
      alert("Message saved successfully");
    } catch (error) {
      console.error("Error saving message", error);
      alert("Error saving message");
    }
  };
  return (
    <Container fluid className="my-form d-flex align-items-center">
      <Row className="justify-content-center w-100">
        <Col xs={12} className="text-center mb-5">
          <h1>Tinggalkan Pesan</h1>
        </Col>
        <Col xs={10}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formNamaLengkap" className="mb-3">
              <Form.Control type="text" placeholder="Nama Lengkap" name="nama" className="form-size" />
            </Form.Group>
            <Row className="justify-content-between mb-3">
              <Col xs={10} sm={6}>
                <Form.Group controlId="formEmail">
                  <Form.Control type="email" placeholder="Email" name="email" className="form-size" />
                </Form.Group>
              </Col>
              <Col xs={10} sm={6}>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1" style={{ height: "64px" }}>
                    +62
                  </InputGroup.Text>
                  <Form.Control type="tel" pattern="[0-9]*" inputMode="numeric" placeholder="Masukkan nomor telepon" name="noTelp" className="form-control" style={{ height: "64px" }} />
                </InputGroup>
              </Col>
            </Row>
            <Form.Group controlId="formPesan" className="mb-3">
              <Form.Control as="textarea" name="pesan" placeholder="Pesan" className="form-size" />
            </Form.Group>
            <div className="d-flex">
              <Button type="submit" className="button-size">
                Kirim Pesan
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default FormHome;
