import React from "react";

function CardDetails({ card }) {
  return (
    <div className="container">
      <h1>{card.title}</h1>
      <p>
        <small>
          {new Intl.DateTimeFormat("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }).format(new Date(card.date))}
        </small>
      </p>
      <p>Type: {card.type}</p>
      <img src={card.imageUrl} alt={card.title} className="img-fluid" />
      <p>{card.description}</p>
      <p>{card.description2}</p>
    </div>
  );
}

export default CardDetails;
