import React, { Component } from "react";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";

import "./custom.css";

export default class Catalogcard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogItems: [],
    };
  }

  componentDidMount() {
    axios.get("https://v3421017.mhs.d3tiuns.com/api/kontak_admin").then((response) => {
      this.setState({ catalogItems: response.data });
    });
  }

  render() {
    const { catalogItems } = this.state;

    return (
      <div>
        <h1 className="container mt-5 fw-semibold">Download</h1>
        <Row xs={1} md={2} className="g-4 container row row-cols-1 row-cols-md-2 g-4 mx-auto mt-5 mb-8">
          {catalogItems.map((kontak) => (
            <Col key={kontak.id}>
              <Card className="catalog-card">
                <div className="image-container">
                  <Card.Img variant="top" src={"https://v3421017.mhs.d3tiuns.com/" + kontak.gambar_kontak_admin} />
                </div>
                <Card.Body className="card-body-katalog">
                  <Card.Title>
                    <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                    {kontak.nama_kontak_admin}
                  </Card.Title>
                  <Button className="btn-katalog" href={kontak.nomor_kontak_admin} target="_blank" rel="noopener noreferrer">Download</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}
