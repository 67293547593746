import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

const GalleryComponent = () => {
  const [cardFilter, setCardFilter] = useState("produk");
  const [showAllCards, setShowAllCards] = useState(false);

  const cardData = [
    {
      title: "Blangkon Jogja",
      description: "Rp 100.000",
      imgSrc: "https://down-id.img.susercontent.com/file/6c48451a3aa57e47da9aa2b8e14f7f21",
      type: "produk",
    },
    {
      title: "Mega Mendung",
      description: "Keindahan dan Kesempurnaan",
      imgSrc: "https://i.pinimg.com/564x/2b/8f/ad/2b8fadda0f3e7a7b882a0bca67efd123.jpg",
      type: "motif",
    },
    {
      title: "Blangkon Surakarta",
      description: "Rp 80.000",
      imgSrc: "https://upload.wikimedia.org/wikipedia/commons/5/5d/Ngayogyakarta-style_blangkon%2C_2015-05-17_04.jpg",
      type: "produk",
    },
    {
      title: "Purbonegoro",
      description: "Kebesaran dan Kejayaan",
      imgSrc: "https://images.tokopedia.net/img/cache/700/VqbcmM/2021/2/4/35b8eed0-dedb-4d3f-97df-f7cf91448d20.jpg",
      type: "motif",
    },
    {
      title: "Nitik",
      description: "Kebersamaan dan Kerja sama",
      imgSrc: "https://i.pinimg.com/originals/a7/68/e8/a768e88adfe62a7d9bfb84ef3f07da3c.jpg",
      type: "motif",
    },
    {
      title: "Batik Parang Barong",
      description: "Kepempipinan dan Keberanian",
      imgSrc: "https://o-cdf.sirclocdn.com/unsafe/o-cdn-cas.sirclocdn.com/parenting/images/Batik_Parang_Lereng.width-800.jpegquality-80.png",
      type: "motif",
    },
    {
      title: "Baju Batik Kawung",
      description: "Rp 125.000",
      imgSrc: "https://www.static-src.com/wcsstore/Indraprastha/images/catalog/full//108/MTA-6829695/batik_adikusuma_batik_adikusuma_hem_batik_kawung_salur_orange_532299020_full02_tmgyuqg2.jpg",
      type: "produk",
    },
    {
      title: "Baju Batik Parang",
      description: "Rp 150.000",
      imgSrc: "https://s1.bukalapak.com/img/62238988192/large/data.jpeg",
      type: "produk",
    },
    {
      title: "Baju Batik Barong",
      description: "Rp 150.000",
      imgSrc: "https://s1.bukalapak.com/img/62238988192/large/data.jpeg",
      type: "produk",
    },
  ];

  const filteredCards = cardData.filter((card) => card.type === cardFilter);
  const visibleCards = showAllCards ? filteredCards : filteredCards.slice(0, 4);

  const renderCard = (card) => (
    <div style={{ position: "relative", width: "580px", height: "290px", borderRadius: "10px" }}>
      <img
        src={card.imgSrc}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          transition: "transform 0.3s ease-out",
          transformOrigin: "center",
          ":hover": {
            transform: "scale(1.1)",
          },
        }}
        alt={card.title}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card.Title style={{ color: "white", textAlign: "center" }}>{card.title}</Card.Title>
        <Card.Text style={{ color: "white", textAlign: "center" }}>{card.description}</Card.Text>
      </div>
    </div>
  );

  return (
    <Container>
      <h1 className="text-center mb-4 mt-5 fw-bold">Gallery Joglo Bale Agung</h1>
      <p className="text-center">Beberapa Gallery Produk & Motif yang dibuat di Joglo Bale Agung Cendana</p>
      <Row className="mt-4 mb-4">
        <Col className="text-center">
          <Button onClick={() => setCardFilter("produk")} variant={cardFilter === "produk" ? "dark" : "outline-dark"}>
            Produk
          </Button>{" "}
          <Button onClick={() => setCardFilter("motif")} variant={cardFilter === "motif" ? "dark" : "outline-dark"}>
            Motif
          </Button>
        </Col>
      </Row>
      <Row>
        {visibleCards.map((card, index) => (
          <Col key={index} md={6} className="d-flex justify-content-center mb-4">
            {renderCard(card)}
          </Col>
        ))}
      </Row>
      <Row className="mt-2 mb-5">
        <Col className="text-center">
          {!showAllCards && filteredCards.length > 4 ? (
            <Button variant="outline-dark" onClick={() => setShowAllCards(true)}>
              Lebih Banyak
            </Button>
          ) : (
            showAllCards && (
              <Button variant="outline-dark" onClick={() => setShowAllCards(false)}>
                Kecilkan
              </Button>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default GalleryComponent;
