import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Slider from "react-slick";
import layananImage from "../assets/layanan.svg";
import "./layanan.css";

const CardCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [layanans, setLayanans] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://v3421017.mhs.d3tiuns.com/api/layanan");
        setLayanans(response.data);
      } catch (error) {
        console.error("Error fetching layanans:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="carousel-container">
      <h1 className="carousel-title">
        Layanan yang tersedia di
        <br /> Kampung Batik Joglo Bale Agung
      </h1>
      <div className="carousel-wrapper">
        <Slider {...settings}>
          {layanans.map((layanan) => (
            <div key={layanan.id}>
              <Card className="carousel-card" style={{ background: `url(${layananImage})`, backgroundSize: "cover" }}>
                <Card.Img className="illustration illustration-img" src={"https://v3421017.mhs.d3tiuns.com/" + layanan.gambar_layanan} />
                <Card.Body className="carousel-card-body">
                  <Card.Title>{layanan.nama_layanan}</Card.Title>
                  <Card.Text className="carousel-card-text mb-4">{layanan.deskripsi_layanan}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CardCarousel;
