import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import ArticleDetails from "../components/artikel/artikeldetail";
import Content from "../components/artikel/content";
import Header from "../components/artikel/header";

export default class Artikel extends Component {
  render() {
    return (
      <div>
        <Header />
        <Routes>
          <Route exact path="/" element={<Content />} />
          <Route path="/article/:title" element={<ArticleDetails />} />
        </Routes>
      </div>
    );
  }
}
